<!--
 * @Author       : JiangChao
 * @Date         : 2022-12-12 13:30:56
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-22 17:34:58
 * @Description  : applepay/linepay/px/jk
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <!-- <div style="font-size: 18px">支付押金</div> -->
            <div class="logo">
                <div>押金</div>
                <div style="font-weight: 500">NT$384</div>
                <van-image
                    width="30px"
                    height="36px"
                    :src="iconContentYajin"
                ></van-image>
            </div>
            <div class="card">
                <van-cell
                    title="支付方式"
                    style="font-weight: 500; text-align: left"
                    clickable
                    @click="radio = '1'"
                >
                </van-cell>
                <van-radio-group v-model="radio" @change="onChange">
                    <van-cell-group :border="false">
                        <!-- ApplePay-->
                        <!-- <van-cell title="" clickable @click="radio = '1'">
                            <template #icon>
                                <van-image
                                    width="70px"
                                    height="60px"
                                    :src="applePayIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="1">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell> -->
                        <!-- GooglePay -->
                        <!-- <van-cell title="" clickable @click="radio = '2'">
                            <template #icon>
                                <van-image
                                    width="100px"
                                    height="36px"
                                    :src="googlePayIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="2">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell> -->
                        <!-- Line支付 -->
                        <van-cell title="" clickable @click="radio = '3'">
                            <template #icon>
                                <van-image
                                    width="100px"
                                    height="30px"
                                    :src="linePayIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="3">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <!-- 全支付 -->
                        <!-- <van-cell title="" clickable @click="radio = '4'">
                            <template #icon>
                                <van-image
                                    width="100px"
                                    height="40px"
                                    :src="pxIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="4">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell> -->
                        <!-- 街口支付 -->
                        <!-- <van-cell title="" clickable @click="radio = '5'">
                            <template #icon>
                                <van-image
                                    width="100px"
                                    height="50px"
                                    :src="jkIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="5">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell> -->
                    </van-cell-group>
                </van-radio-group>
                <!-- <van-cell
                    title="街口支付需绑定银行账户"
                    style="text-align: left"
                >
                </van-cell> -->
            </div>

            <div class="read-moudle">
                <van-checkbox v-model="isCheck" @change="readChange">
                    <template #icon="props">
                        <van-image
                            width="18px"
                            height="18px"
                            :src="
                                props.checked
                                    ? activeClauseIcon
                                    : inactiveClauseIcon
                            "
                        />
                    </template>
                </van-checkbox>

                <span class="clause"
                    >點擊確認付款，表示已閱讀並同意《用戶協議》</span
                >
            </div>
              <p style="font-size: 12px; text-align: left;margin-left:27px">
                支付完成請耐心等待畫面跳轉，請勿手動操作返回避免重複支付押金
            </p>
            <div class="login-way">
                <div class="sms-way" @click="confirm">確認付款</div>
                <!-- <div class="ui grid centered stackable">
					<div class="row"></div>
					<div class="row"></div>
					<br>
					<br>
					<div id="container"></div>
					<div class="fourteen wide column">
						<div class="ui info message hidden result1">
							<div class="ui header">Get Prime Result</div>
							<pre class="content" id="result1" style="overflow-x: auto;"></pre>
						</div>
						<div class="ui success message hidden curl">
							<div class="ui header">Pay Result</div>
							<pre class="content" id="curl" style="overflow-x: auto;"></pre>
						</div>
					</div>
				</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import _ from "lodash";

export default {
    name: "",
    data() {
        return {
            radio: "",
            activeIcon: require("@/assets/imgs/png/icon_agree_selected_circle.png"),
            inactiveIcon: require("@/assets/imgs/png/icon_login_default_circle.png"),
            iconContentYajin: require("@/assets/imgs/png/icon_content_yajin.png"),
            applePayIcon: require("@/assets/imgs/png/apple_pay.png"),
            jkIcon: require("@/assets/imgs/png/img_pay.png"),
            googlePayIcon: require("@/assets/imgs/png/google_pay.png"),
            linePayIcon: require("@/assets/imgs/png/line_pay.jpg"),
            pxIcon: require("@/assets/imgs/png/ic_px.jpg"),
            // mastercardPayIcon: require("@/assets/imgs/png/mastercard_pay.png"),
            // visaPayIcon: require("@/assets/imgs/png/visa_pay.png"),
            // subscriptionIcon: require("@/assets/imgs/png/icon_subscription.png"),
            activeClauseIcon: require("@/assets/imgs/png/icon_agree_selected.png"),
            inactiveClauseIcon: require("@/assets/imgs/png/icon_login_default.png"),
            isCheck: false,
            userId: "",
            token: "",
        };
    },
    methods: {
        onChange(val) {
            console.log("val: ", val);
            this.radio = val;
        },

        confirm: _.throttle(function () {
            if (!this.isCheck) {
                handleErr({
                    app: this.$parent,
                    msg: "請確認已閱讀並同意《用戶協議》！",
                });
                return;
            }
            if (!this.radio) {
                handleErr({ app: this.$parent, msg: "請選擇支付方式！" });
                return;
            }

            switch (this.radio) {
                case "1":
                    console.log("ApplePay");
                    this.$_href("ApplePay");
                    break;
                case "2":
                    this.GooglePay();
                    break;
                case "3":
                    this.LinePay();
                    break;
                case "4":
                    this.PXPay();
                    break;
                case "5":
                    this.JKPay();
                    break;
            }
        }, 2000),

        $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                this.$router.push(page);
            }
        },
        readChange(val) {
            console.log("val: ", val);
            if (val) {
                this.isCheck = true;
            } else {
                this.isCheck = false;
            }
        },

        GooglePay() {
            var paymentRequest = {
                allowedNetworks: ["AMEX", "JCB", "MASTERCARD", "VISA"],
                price: "384", // optional
                currency: "TWD", // optional
            };
            window.TPDirect.googlePay.setupPaymentRequest(
                paymentRequest,
                function (err, result) {
                    console.log("result: ", result);
                    if (result.canUseGooglePay) {
                        window.TPDirect.googlePay.getPrime(function (
                            err,
                            prime
                        ) {
                            console.log("prime: ", prime);
                            console.log("err: ", err);
                            // Get the prime, and send it to Server
                        });
                        // window.TPDirect.googlePay.setupGooglePayButton({
                        // 	el: "#container",
                        // 	color: "black",
                        // 	type: "long",
                        // 	getPrimeCallback: function (err, prime) {
                        // 		console.log(
                        // 			"paymentRequestApi.getPrime result",
                        // 			prime
                        // 		);
                        // 		this.handlePayByPrime(prime);
                        // 	},
                        // });
                    }
                }
            );
            // this.$_href("GooglePay");
        },
        initGooglePay() {
            var googlePaySetting = {
                googleMerchantId: "Come from google portal",
                tappayGoogleMerchantId: "0100063143", //Come from tappay portal
                allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                merchantName: "旅電",
                // emailRequired: true, // optional
                // shippingAddressRequired: true, // optional,
                // billingAddressRequired: true, // optional
                billingAddressFormat: "MIN", // FULL, MIN

                allowPrepaidCards: true,
                allowedCountryCodes: ["TW"],

                // phoneNumberRequired: true, // optional
            };
            window.TPDirect.googlePay.setupGooglePay(googlePaySetting);
        },
        handlePayByPrime(result) {
            document.querySelector("#result1").innerHTML = JSON.stringify(
                result,
                null,
                4
            );
            document.querySelector(".result1").classList.remove("hidden");
            document.querySelector(".curl").classList.remove("hidden");
            var command = `
        curl -X POST https://sandbox.tappaysdk.com/tpc/payment/pay-by-prime \\
        -H 'content-type: application/json' \\
        -H 'x-api-key: partner_6ID1DoDlaPrfHw6HBZsULfTYtDmWs0q0ZZGKMBpp4YICWBxgK97eK3RM' \\
        -d '{
            "partner_key": "partner_6ID1DoDlaPrfHw6HBZsULfTYtDmWs0q0ZZGKMBpp4YICWBxgK97eK3RM",
            "prime": "${result}",
            "amount": "1",
            "merchant_id": "GlobalTesting_CTBC",
            "details": "Some item",
            "cardholder": {
                "phone_number": "0987654321",
                "name": "王小明",
                "email": "test@example.com",
                "zip_code": "123",
                "address": "台北市xxx街xx號",
                "national_id": "A123456789"
            }
        }'`.replace(/^/gm, "");

            document.querySelector("#curl").innerHTML = command;
        },
        async LinePay() {
            try {
                const res = await this.$axios.post("linePay/payment3", {
                    reUrl: "",
                    sn: "",
                    userId: this.userId,
                });
                if (res.data.success) {
                    let url = res.data.result.webUrl;
                    console.log("url: ", url);
                    window.location.href = url;
                }
            } catch (error) {
                console.log(error, "error rent device");
                handleErr({ ...error, app: this.$parent });
            }
        },
        async PXPay() {
            try {
                const res = await this.$axios.post("tw/pxPay", {
                    token: this.token,
                });
                if (res.data.success) {
                    let url = res.data.result.payment_url;
                    console.log("url: ", url);
                    window.location.href = url;
                }
            } catch (error) {
                console.log(error, "error rent device");
                handleErr({ ...error, app: this.$parent });
            }
        },
        async JKPay() {
            try {
                const res = await this.$axios.post("tw/requestPay", {
                    reUrl: "",
                    sn: "",
                    userId: this.userId,
                });
                if (res.data.success) {
                    let url = res.data.result.result_object.payment_url;
                    console.log("url: ", url);
                    // window.location.href = url;
                }
            } catch (error) {
                console.log(error, "error rent device");
                handleErr({ ...error, app: this.$parent });
            }
        },
         async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log("res: ", res);
                if (res.data.success) {
                    this.userId = res.data.result.id;
                    console.log("this.userId: ", this.userId);
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        this.initGooglePay();

        // this.token = localStorage.getItem("token");
        this.token = window.$cookies.get("token");

        if (this.token) {
            this.$axios.defaults.headers.common["token"] = this.token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        // this.userId = JSON.parse(sessionStorage.getItem("userInfo")).userId;
        this.getUserInfo();
        // console.log("this.userId: ", this.userId);
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: #fee26c;
    text-align: center;
    padding: 20px;
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 10px;
        line-height: 50px;
    }
    .card {
        border-radius: 10px;
        margin-top: 20px;
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
    .tip {
        position: fixed;
        bottom: 0;
        text-align: left;
        margin-bottom: 30px;
        p {
            font-size: 14px;
        }
    }
    .read-moudle {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        .clause {
            font-size: 12px;
            font-family: SourceHanSansCN, SourceHanSansCN-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 12px;
            margin-left: 10px;
        }
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
}
</style>
