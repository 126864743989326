import { render, staticRenderFns } from "./PayByMultiChannel.vue?vue&type=template&id=25590b6e&scoped=true&"
import script from "./PayByMultiChannel.vue?vue&type=script&lang=js&"
export * from "./PayByMultiChannel.vue?vue&type=script&lang=js&"
import style0 from "./PayByMultiChannel.vue?vue&type=style&index=0&id=25590b6e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25590b6e",
  null
  
)

export default component.exports